<template>
  <v-container class="pa-0" fluid v-if="device">
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            src="@/assets/images/machine_top.jpg"
            cover
            position="top center"
            max-height="800"/>
      </v-col>
    </v-row>
    <v-card tile flat color="#f0f0f0">
      <v-container class="px-5">
        <v-row class="py-5">
          <v-col>
            <p class="descriptiontitle font-weight-bold text-center black--text mt-5 mb-0">
              {{ device.fullname }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-8" v-if="device.img">
            <v-img :src="device.img" width="100%" class="mx-auto"/>
          </v-col>

          <v-col cols="12" class="py-8" v-if="device.img2">
            <v-img :src="device.img2" width="100%" class="mx-auto"/>
          </v-col>

          <v-col cols="12" class="pt-3">
            <p class="split_line">{{ $t("machinedetail.info") }}</p>
          </v-col>

          <v-col>
            <div style="max-width: 800px; margin: 0 auto;">
              <p class="" style="font-size: 20px;" v-html="device.desc"></p>
            </div>
          </v-col>

          <v-col cols="12" v-if="device.detail.length">
            <p class="split_line">{{ $t("machinedetail.function") }}</p>
          </v-col>

          <v-col v-if="device.detail.length">
            <div style="max-width: 800px; margin: 0 auto;">
              <p v-for="(item, index) in device.detail" :key="index" style="font-size: 20px;">
                {{ item }}
              </p>
            </div>
          </v-col>

          <v-col cols="12" v-if="device.productRef">
            <div style="max-width: 800px; margin: 0 auto;">
              <p style="font-size: 20px; text-align: center">
                <router-link class="text-decoration-none" :to="device.productRef">
                  <v-btn tile
                         x-large
                         outlined
                         elevation="0"
                         class="green--text"
                         color="green darken-4">
                    {{ $t("ref_product") }}
                  </v-btn>
                </router-link>
              </p>
            </div>
          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import devicearr from '@/data/devicearr.js'
import _         from 'lodash'

export default {
  props : ['id'],
  created () {
    const device = _.flatten(devicearr[this.$i18n.locale]).filter(item => item.id == this.id)
    this.device = device.length ? device[0] : null
  },
  watch    : {
    '$i18n.locale' : function () {
      const device = _.flatten(devicearr[this.$i18n.locale]).filter(item => item.id == this.id)
      this.device = device.length ? device[0] : null
    },
  },
  computed : {
    infoArr () {
      return this.$t("machinedetail.machine." + this.product + ".info")
    },
    functionArr () {
      return this.$t("machinedetail.machine." + this.product + ".function")
    }
  },
  data     : () => ({
    product : 'item1',
    device  : null
  })
}
</script>
