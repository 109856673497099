<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            src="@/assets/billboard/other.jpg"
            max-height="600"
        />
      </v-col>
    </v-row>
    <v-card tile flat color="#f0f0f0">
      <v-container class="px-5" fluid>
        <v-row class="py-5 px-0">
          <v-col cols="12">
            <p class="descriptiontitle font-weight-bold text-center green--text text--darken-4 text--darken-3 mt-5 mb-0">
              {{ $t("page_name.downloads") }}
            </p>
          </v-col>
          <v-col cols="12 text-center" style="min-height:600px;">
            <a target="_blank" href="/download/sanfung_leaflet_compressed.pdf">{{ $t('downloads.coloured_corrugated_steel_sheets') }}</a>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import api from "../../api";

export default {
  created () {
    this.getData()
  },
  data    : () => ({
    data : null
  }),
  methods : {
    async getData () {
      try {
        let result = await api.get('/setting/tandc')
        if (result.data) {
          this.data = result.data
        }
      } catch (err) {
        // return err.response.data.error.message
      }

    }
  }
}
</script>
