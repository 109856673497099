import Vue                from 'vue'
import VueRouter          from 'vue-router'
import Home               from '@/components/view/Home'
import ProductCategory    from '@/components/view/ProductCategory'
import Products           from '@/components/view/Products'
import Video              from '@/components/view/Video'
import Aboutus            from '@/components/view/Aboutus'
import TermsAndConditions from '@/components/view/TermsAndConditions'
import Downloads          from '@/components/view/Downloads'
import PrivacyPolicy      from '@/components/view/PrivacyPolicy'
import Contectus          from '@/components/view/Contectus'
import Standard           from '@/components/view/Standard'
import NotFound           from '@/components/view/NotFound'
import Machines           from '@/components/view/Machines'
import MachinesDetail     from '@/components/view/MachinesDetail'
import MachinesList       from '@/components/view/MachinesList'

Vue.use(VueRouter)

const router = new VueRouter({
	mode   : 'history',
	base   : process.env.BASE_URL,
	routes : [
		{
			path      : '/',
			name      : 'Home',
			component : Home
		},
		{
			path      : '/productcategory',
			name      : 'ProductCategory',
			component : ProductCategory
		},
		{
			path      : '/products/',
			name      : 'Products',
			component : Products,
			props     : route => ({ params : route.params.catid, query : route.query.id }),
			children  : [
				{
					// UserProfile will be rendered inside User's <router-view>
					// when /user/:id/profile is matched
					path      : ':catid',
					component : Products
				},
			]
		},
		{
			path      : '/video',
			name      : 'Video',
			component : Video
		},
		{
			path      : '/machines',
			name      : 'Machines',
			component : Machines
		},
		{
			path      : '/machinesdetail/:id',
			name      : 'MachinesDetail',
			component : MachinesDetail,
			props     : true
		},
		{
			path      : '/machineslist',
			name      : 'MachinesList',
			component : MachinesList
		},
		{
			path      : '/aboutus',
			name      : 'Aboutus',
			component : Aboutus
		},
		{
			path      : '/pp',
			name      : 'PrivacyPolicy',
			component : PrivacyPolicy
		},
		{
			path      : '/tnc',
			name      : 'TermsAndConditions',
			component : TermsAndConditions
		},
		{
			path      : '/standard',
			name      : 'Standard',
			component : Standard
		},
		{
			path      : '/contactus',
			name      : 'Contectus',
			component : Contectus
		},
		{
			path      : '/downloads',
			name      : 'Downloads',
			component : Downloads
		},
		{ path : '*', component : NotFound }
	],
	scrollBehavior () {
		// return desired position
		return { x : 0, y : 0 }
	}
})

export default router
