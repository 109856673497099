<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            src="@/assets/images/machine_top.jpg"
            cover
            position="top center"
            max-height="800"
        />
      </v-col>
    </v-row>
    <v-card tile flat color="#f0f0f0">
      <v-container class="px-5" fluid>
        <v-row class="py-5">
          <v-col>
            <p class="description font-weight-bold text-center green--text text--darken-4 mt-5 mb-0">
              {{ $t("machines.sub_title") }}
            </p>
            <p class="descriptiontitle font-weight-bold text-center mb-0">
              {{ $t("machines.title") }}
            </p>
          </v-col>
        </v-row>

        <v-row class="py-5 px-0 desktop">
          <v-col cols=12 md=4>
            <v-img src="@/assets/images/machine_1.jpg">
            </v-img>
          </v-col>
          <v-col cols=12 md=4>
            <v-img src="@/assets/images/machine_2.jpg">
            </v-img>
          </v-col>
          <v-col cols=12 md=4>
            <v-img src="@/assets/images/machine_3_2.jpg">
            </v-img>
          </v-col>
        </v-row>

        <v-row v-for="(content, arr_index) in devicearr[$i18n.locale]" :key="arr_index" style="align-items: flex-end">
          <v-col cols=12 md=12 lg=4 v-for="(value, key) in content" :key="key">
            <!--            <p v-if="arr_index == 0" class="devicelist"/>-->
            <router-link class="text-decoration-none black--text" :to="{ name: 'MachinesDetail', params:{id: value.id}}">
              <p style="font-size: 20px">
                <v-icon color="green darken-4">{{ (value.selected) ? "mdi-chevron-right-circle" : "mdi-chevron-right-circle-outline" }}</v-icon>
                {{ value.fullname }}
              </p>
            </router-link>
            <p class="devicelist"/>
          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import devicearr from '@/data/devicearr.js'

export default {
  created () {
    this.devicearr = devicearr
  },
  data : () => ({
    machinestitle       : '備有多台先進大型機械設備',
    machinesdescription : '香港生產線',
    devicearr           : []
  })
}
</script>
