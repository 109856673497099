import Vue        from 'vue'
import App        from './App.vue'
import vuetify    from './plugins/vuetify'
import router     from './router/router'
import VueI18n    from 'vue-i18n'
import Controller from './controller'
import store      from "./store";
import tc         from './locales/tc'
import sc         from './locales/sc'
import eng        from './locales/en'
import api        from './api'
import './firebase'

Vue.config.productionTip = false

Vue.use(VueI18n)

// 預設使用的語系
let locale = 'tc';
let locales = ['tc', 'sc', 'eng']
Vue.prototype.$Controller = new Controller()
Vue.prototype.$api = api

if (localStorage.getItem('footmark-lang') && locales.includes(localStorage.getItem('footmark-lang'))) locale = localStorage.getItem('footmark-lang');

store.commit('setLang', locale);

const i18n = new VueI18n({
	locale   : locale,
	messages : {
		'tc'  : tc,
		'sc'  : sc,
		'eng' : eng
	}
});

new Vue({
	vuetify,
	router : router,
	i18n,
	store,
	render : h => h(App)
}).$mount('#app')
