<template>
  <v-footer padless>
    <v-card class="flex" tile flat>
      <v-card class="pt-10" color="#ECEFF1" tile flat>
        <v-container>
          <v-row class="pa-0 mb-8" no-gutters>
            <v-col justify="left" cols="12" lg="4" md="12" class="mb-6">
              <v-card-title class="headline pa-0">
                <v-img
                    max-width="350"
                    src="@/assets/sanfung_company_logo_footer.png"
                />
              </v-card-title>
              <p class="footer_address" v-html="$t('company_info.address')"></p>
              <div class="font-weight-black">
                <v-btn v-for="(lang, index) in langs"
                       :key="index"
                       :class="($i18n.locale === lang.value)? 'green--text text--darken-4' : ''"
                       color="transparent"
                       depressed
                       medium
                       text
                       raised
                       tile
                       @click="changeLang(lang.value)">{{ lang.name }}
                  <span v-if="index < (langs.length -1)" class="pl-5">|</span></v-btn>
              </div>
            </v-col>
            <v-col class="footer-nav pl-3" cols="12" lg="3" md="4">
              <p class="font-weight-bold py-3 pl-2" v-for="(info, name, index) in footer_arr.col1" :key="index">
                <template v-if="name === 'whatsapp'">
                  <v-img style="display: inline-block; vertical-align: middle; margin-right: 8px;" src="@/assets/images/wa.png" width="30"></v-img>
                  |
                  <a target="_blank" :href="'https://wa.me/+852'+info[0].replace(' ','')" style="text-decoration: none; color: black;">{{ info[0] }}</a>,
                  <a target="_blank" :href="'https://wa.me/+852'+info[1].replace(' ','')" style="text-decoration: none; color: black;">{{ info[1] }}</a>
                </template>
                <template v-else>
                  <b>{{ $t(name) }}</b> | {{ info }}
                </template>
              </p>
            </v-col>
            <v-col class="footer-nav pl-3" cols="12" lg="2" md="4">
              <p class="font-weight-bold py-3 pl-2" v-for="(link, name, index) in footer_arr.col2" :key="index">
                <router-link :to="link" class="text-decoration-none black--text">
                  {{ $t(name) }}
                </router-link>
              </p>
            </v-col>
            <v-col class="footer-nav pl-3" cols="12" lg="2" md="4">
              <p class="font-weight-bold py-3 pl-2" v-for="(link, name, index) in footer_arr.col3" :key="index">
                <router-link :to="link" class="text-decoration-none black--text">
                  {{ $t(name) }}
                </router-link>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-container>
        <v-row>
          <v-col class="py-10">
            <h5 class="copyright">
              Copyright © {{ new Date().getFullYear() }} San Fung Steel Company Limited. All rights reserved.
            </h5>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  methods : {
    changeLang (newLang) {
      this.$store.commit('setLang', newLang);
      this.$i18n.locale = newLang;
      localStorage.setItem('footmark-lang', newLang);
    }
  },
  data    : () => ({
    footer_arr : {
      col1 : {
        'company_info.phone_number' : '2479 0383, 2479 7981',
        'whatsapp'                  : ['5401 3775', '6360 4510'],
        'company_info.fax'          : '2474 3688, 2470 0772',
        'company_info.email'        : 'info@sanfungsteel.com.hk',
        // 'company_info.whatsapp': '3323 3323 / 3332 0093',
      },
      col2 : {
        'page_name.product' : '/products',
        'page_name.aboutus' : '/aboutus',
        'page_name.tnc'     : '/tnc',
        // 'page_name.video'    : '/video',

      },
      col3 : {
        'page_name.contectus' : '/contactus',
        'page_name.machines'  : '/machines',
        'page_name.pp'        : '/pp',
      },
    },
    langs      : [
      { name : '繁', value : 'tc' },
      { name : '簡', value : 'sc' },
      { name : 'Eng', value : 'eng' },
    ],
  }),
}
</script>
