<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('custom_from') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="product">
                <span class="black--text">{{ product['name_' + lang] }}</span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('custom_describe') }}</span>
                <v-textarea v-model="custom_remark"></v-textarea>
              </v-col>
              <v-col cols="12">
                <div style="max-width: 200px;">
                  <span>{{ $t('quantity') }}</span>
                  <v-text-field v-model="quantity" class="centered-input">
                    <v-icon slot="append" @click="plusQuantity()">mdi-plus</v-icon>
                    <v-icon slot="prepend-inner" @click="minusQuantity()">mdi-minus</v-icon>
                  </v-text-field>
                </div>
              </v-col>
              <v-col>
                <span>{{ $t('upload_images') }}</span>
                <v-row>
                  <v-col cols="auto">
                    <input ref="imageInput" style="display:none" type="file" @change="onFileSelected" accept="image/png, image/jpeg"/>
                    <v-card v-if="loading" class="pa-2 image_card" outlined tile>
                      <v-progress-circular
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                    </v-card>
                    <v-card
                        v-else
                        class="pa-2 image_card"
                        outlined
                        tile
                        @click="$refs.imageInput.click()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-card>
                  </v-col>
                  <template v-if="images.length > 0">
                    <v-col cols="auto" v-for="img in images" v-bind:key="img._id">
                      <v-card
                          class="pa-2 image_card"
                          outlined
                          tile>
                        <v-img :src="img.small.path"></v-img>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="submitOrderItem()">{{ $t('confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {mapState} from 'vuex'

export default {
  data     : () => ({
    loading       : false,
    dialog        : false,
    product       : null,
    quantity      : 1,
    custom_remark : '',
    images        : [],
    selectedFile  : null
  }),
  computed : {
    ...mapState({ lang : state => state.lang })
  },
  methods  : {
    open (product, quantity) {
      if (product) {
        this.product = product;
      }
      this.quantity = quantity,
          this.dialog = true;
    },

    close () {
      this.product = null;
      this.quantity = 1;
      this.custom_remark = '';
      this.images = [];
      this.dialog = false;
    },

    plusQuantity () {
      this.quantity++;
    },
    minusQuantity () {
      if (this.quantity > 1) this.quantity--;
    },

    async onFileSelected (event) {
      this.selectedFile = event.target.files[0];
      this.loading = true
      let result = await this.$Controller.uploadImage(this.selectedFile);
      this.$refs.imageInput.value = null;
      if (result.small) {
        this.images.push(result);
      }
      this.loading = false;
    },

    async submitOrderItem () {
      try {


        let product = (this.product && this.product._id) ? this.product._id : null;
        let quantity = this.quantity;
        let custom_remark = this.custom_remark;
        let images = this.images.map(item => item._id);
        let result = await this.$Controller.customSubmit(product, quantity, custom_remark, images);
        if (result) {
          // window.location.reload();
          window.sanfungCart.update()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.close();
      }
    }
  }
}
</script>
