export default {
	tc  : [
		[
			{
				id         : 1,
				name       : 'a',
				fullname   : '浪板成型機',
				selected   : false,
				desc       : "此機出品之鉛水瓦坑鐵，其特式是坑形比舊式機械更為高，<br>防漏能力更佳，穩定性良好，既美觀又實用。<br>以同樣的原材料生產，品質相比舊式機械生產之瓦坑鐵更為堅固。<br>同時，亦可按照客戶要求而剪裁不同的長度(最長可達40尺)，<br>可避免因長度不足而出現搭接的情況，<br></br>適用範圍：地盤圍板、車房、農場、貨倉、村屋屋頂、廠房蓋建等。",
				detail     : ["厚度：0.35mm - 0.7mm，型號：SF-6 / SF-800"],
				img        : '/img/浪板成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad97113d"
			},
			{
				id         : 2,
				name       : 'b',
				fullname   : '全電腦數控 - 剪摺床機',
				selected   : false,
				desc       : "本公司備有多台“全電腦數控”剪摺床機，可代客戶來料加工，按所需的尺吋和長度而剪摺成不同的鋼材制品，厚度由0.5-12mm不等。適用於剪裁及屈摺各類型貨櫃、坑旁、貨車坑斗、電箱、去水槽、各類五金製品、鐵板、不銹鋼板及其他金屬鋼板等。",
				detail     : [],
				img        : '/img/全電腦數控.png',
				productRef : "/products?id=619e810a4b9d120cad971119"
			},
			{
				id         : 3,
				name       : 'c',
				fullname   : 'CNC鋼卷整平剪裁機(6.0mm)',
				selected   : false,
				desc       : "此機為現時台灣製造最精良及先進之鋼卷整平剪裁機，可按客戶用途和要求，將不同品種之金屬鋼卷剪裁成不同長度及尺吋，以達致減少原料耗損，降低客生產成本。在完成後更可為鋼材表面铺上保護膠紙及包裝。",
				detail     : ["厚度：1.5mm - 6.0mm X 5呎闊", "鋼卷重量：20噸以下", "鋼卷內徑：508mm-760mm", "特點：兩組整平(粗整、細整)",
					"可剪裁鋼卷類型：不銹鋼卷、熱軋、鍍鋅、電解、冷軋(單光及雙光)、焗漆、鋁及銅鋼等等"],
				img        : '/img/CNC鋼卷整平剪裁機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971117"
			}
		],
		[
			{
				id         : 4,
				name       : 'f',
				fullname   : '角鐵成型機',
				selected   : false,
				desc       : "為配合客戶靈活運用，角鐵機除了可生產出一般 6M 長度外，<br>更可按照客戶所需的長度而生產，以達致減少原料耗損和降低客戶的成本。",
				detail     : ["厚度：2.5mm至4.5mm", "寬度：20mm x 20mm至75mm x 75mm", "長度：6M (可訂造不定尺長度)", "已成型角鐵類型：鉛水、黑鐵"],
				img        : '/img/角鐵成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971131"
			},
			{
				id         : 5,
				name       : 'e',
				fullname   : '彩色焗漆環保方坑機',
				selected   : false,
				desc       : "本公司出品彩色焗漆環保方坑板，唯現時最新款式，採用品質最優良之原料，<br>設計獨特，外形美觀，是防銹能力極高之焗漆彩板，使用壽命特長。<br></br>並可按照客戶要求而剪裁不同尺吋(最長可達40尺)，備有不同顏色以作配襯，再以自攻羅絲安裝施工，簡單快捷。",
				detail     : ["適用範圍：地盤圍板、車房、農場、貨倉、村屋屋頂、廠房蓋建(歐美國家普遍採用)等。", "顏色選擇：白、灰、深灰、藍、米、白綠、環保綠、深綠、霧綠(本司將會陸續引進更多、更新的顏色以供客戶選擇)",
					"厚度：0.5mm及0.7mm 規格：SF-1 / SF-730、SF-2 / SF-750、SF-3 / SF-760 及 SF-4 / SF-768"],
				img        : '/img/彩色焗漆環保方坑機_2.jpg',
				img2       : '/img/2彩色焗漆環保方坑機.jpg',
				productRef : "/products?id=619e810a4b9d120cad97113b"
			},
			{
				id       : 6,
				name     : 'd',
				fullname : 'STAPS荷蘭鋼卷整平剪裁機(2.0mm)',
				selected : false,
				desc     : "荷蘭製造之剪裁機，由於精密度較“CNC” 6.0mm更為精細，故在剪裁較薄的鋼片時更為平直，並適用於硬度較高的不銹鋼品，效果顯著。在完成後更可為鋼材表面鋪上保護膠紙及包裝。",
				detail   : ["厚度：0.2mm-2.0mm X 4呎闊", "鋼卷重量：10噸以下", "鋼卷內徑：508mm-610mm", "特點：細整", "可剪裁鋼卷類型：不銹鋼卷、熱軋、鍍鋅、電解、冷軋(單光及雙光)、焗漆及銅鋼等等"],
				img      : '/img/STAPS荷蘭鋼卷整平剪裁機_3.jpg'

			},

		],
		[
			{
				id         : 7,
				name       : 'g',
				fullname   : 'C型槽成型機',
				selected   : false,
				desc       : "為配合客戶靈活運用，C型槽機除了可生產出一般 6M 長度外，<br>更可按照客戶所需的長度而生產，以達致減少原料耗損和降低客戶的成本。",
				detail     : ["厚度：2.0mm-3.0mm", "規格：40mm x 60mm", "長度：6M (可訂造不定尺長度)", "已成型C型槽類型：鉛水、黑鐵"],
				img        : '/img/C型槽成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971139"
			},
			{
				id       : 8,
				name     : 'h',
				fullname : '鋼卷自動分條機',
				selected : false,
				desc     : "此機適合用於不同品種的金屬鋼卷，分條成不同寬度的條形板帶。<br>主要是可按客戶需要的寬度而裁剪，生產成各類型制品，其次是為生產過程帶來方便和快捷。<br></br>同時，為配合客戶靈活運用，本分條機更可將鋼卷由大卷分卷成小卷，方便生產時的運作，以達致減低貴廠在生產時的耗損。此機是現時最新、速度最快、精密度最高的分條機之一。",
				detail   : ["厚度：0.3 至3.0mm", "寬度：最少200mm至最大4尺內不等", "可分條的鋼卷類型：鍍鋅、不銹鋼、熱軋、電解、焗漆、銅鋼、冷軋(單光及雙光)"],
				// img      : '/img/鋼卷自動分條機.jpg'
				img        : '/img/鋼卷自動分條機_2.jpg',
				productRef : "/products?id=619e810a4b9d120cad971115"
			},
		],
	],
	sc  : [
		[
			{
				id         : 1,
				name       : 'a',
				fullname   : '浪板成型机',
				selected   : false,
				desc       : "此机出品之铅水瓦坑铁, 其特式是坑形比旧式机械更为高, <br>防漏能力更佳, 稳定性良好, 既美观又实用。<br>以同样的原材料生产, 品质相比旧式机械生产之瓦坑铁更为坚固。<br>同时, 亦可按照客户要求而剪裁不同的长度(最长可达40尺), <br>可避免因长度不足而出现搭接的情况，<br><br>适用范围：地盘围板、车房、农场、货仓、村屋屋顶、厂房盖建等。",
				detail     : ["厚度：0.35mm - 0.7mm 型号：SF-6 / SF-800"],
				img        : '/img/浪板成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad97113d"

			},
			{
				id         : 2,
				name       : 'b',
				fullname   : '全电脑数控 - 剪折床机',
				selected   : false,
				desc       : "本公司备有多台“全电脑数控”剪折床机,可代客户来料加工,按所需的尺吋和长度而剪折成不同的钢材制品,厚度由0.5-12mm不等。适用于剪裁及屈折各类型货柜、坑旁、货车坑斗、电箱、去水槽、各类五金制品、铁板、不锈钢板及其他金属钢板等。",
				detail     : [],
				img        : '/img/全電腦數控.png',
				productRef : "/products?id=619e810a4b9d120cad971119"
			},
			{
				id         : 3,
				name       : 'c',
				fullname   : 'CNC钢卷整平剪裁机(6.0mm)',
				selected   : false,
				desc       : "此机为现时台湾制造最精良及先进之钢卷整平剪裁机, 可按客户用途和要求, 将不同品种之金属钢卷剪裁成不同长度及尺吋, 以达致减少原料耗损, 降低客生产成本。在完成后更可为钢材表面铺上保护胶纸及包装。",
				detail     : ["厚度：1.5mm - 6.0mm X 5呎阔", "钢卷重量：20吨以下", "钢卷内径：508mm-760mm", "特点：两组整平(粗整、细整)",
					"可剪裁钢卷类型：不锈钢卷、热轧、镀锌、电解、冷轧(单光及双光)、焗漆、铝及铜钢等等"],
				img        : '/img/CNC鋼卷整平剪裁機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971117"
			}
		],
		[
			{
				id         : 4,
				name       : 'f',
				fullname   : '角铁成型机',
				selected   : false,
				desc       : "为配合客户灵活运用，角铁机除了可生产出一般 6M 长度外，<br>更可按照客户所需的长度而生产，以达致减少原料耗损和降低客户的成本。",
				detail     : ["厚度：2.5mm至4.5mm", "宽度：20mm x 20mm至75mm x 75mm", "长度：6M (可订造不定尺长度)", "已成型角铁类型：铅水、黑铁"],
				img        : '/img/角鐵成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971131"
			},
			{
				id         : 5,
				name       : 'e',
				fullname   : '彩色焗漆环保方坑机',
				selected   : false,
				desc       : "本公司出品彩色焗漆环保方坑板，唯现时最新款式，采用品质最优良之原料，<br>设计独特，外形美观，是防锈能力极高之焗漆彩板，使用寿命特长。<br></br>并可按照客户要求而剪裁不同尺吋(最长可达40尺)，备有不同颜色以作配衬，再以自攻罗丝安装施工，简单快捷。",
				detail     : ["适用范围：地盘围板、车房、农场、货仓、村屋屋顶、厂房盖建(欧美国家普遍采用)等。", "颜色选择：白、灰、深灰、蓝、米、白绿、环保绿、深绿、雾绿(本司将会陆续引进更多、更新的颜色以供客户选择)",
					"厚度：0.5mm及0.7mm 规格：SF-1 / SF-730、SF-2 / SF-750、SF-3 / SF-760 及 SF-4 / SF-768"],
				img        : '/img/彩色焗漆環保方坑機_2.jpg',
				img2       : '/img/2彩色焗漆環保方坑機.jpg',
				productRef : "/products?id=619e810a4b9d120cad97113b"
			},
			{
				id       : 6,
				name     : 'd',
				fullname : 'STAPS荷兰钢卷整平剪裁机(2.0mm)',
				selected : false,
				desc     : "荷兰制造之剪裁机，由于精密度较“CNC” 6.0mm更为精细，故在剪裁较薄的钢片时更为平直，并适用于硬度较高的不锈钢品，效果显著。在完成后更可为钢材表面铺上保护胶纸及包装。",
				detail   : ["厚度：0.2mm-2.0mm X 4呎阔", "钢卷重量：10吨以下", "钢卷内径：508mm-610mm", "特点：细整", "可剪裁钢卷类型：不锈钢卷、热轧、镀锌、电解、冷轧(单光及双光)、焗漆及铜钢等等"],
				img      : '/img/STAPS荷蘭鋼卷整平剪裁機_3.jpg'
			},
		],
		[
			{
				id         : 7,
				name       : 'g',
				fullname   : 'C型槽成型机',
				selected   : false,
				desc       : "为配合客户灵活运用，C型槽机除了可生产出一般 6M 长度外，更可按照客户所需的长度而生产，以达致减少原料耗损和降低客户的成本。",
				detail     : ["厚度：2.0mm-3.0mm", "规格：40mm x 60mm", "长度：6M (可订造不定尺长度)", "已成型C型槽类型：铅水、黑铁"],
				img        : '/img/C型槽成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971139"

			},
			{
				id       : 8,
				name     : 'h',
				fullname : '钢卷自动分条机',
				selected : false,
				desc     : "此机适合用于不同品种的金属钢卷, 分条成不同宽度的条形板带。<br>主要是可按客户需要的宽度而裁剪, 生产成各类型制品, 其次是为生产过程带来方便和快捷。<br></br>同时, 为配合客户灵活运用, 本分条机更可将钢卷由大卷分卷成小卷, 方便生产时的运作, 以达致减低贵厂在生产时的耗损。此机是现时最新、速度最快、精密度最高的分条机之一。",
				detail   : ["厚度：0.3 至3.0mm", "宽度：最少200mm至最大4尺内不等", "可分条的钢卷类型：镀锌、不锈钢、热轧、电解、焗漆、铜钢、冷轧(单光及双光)"],
				// img      : '/img/鋼卷自動分條機.jpg'
				img        : '/img/鋼卷自動分條機_2.jpg',
				productRef : "/products?id=619e810a4b9d120cad971115"
			}
		],
	],
	eng : [
		[
			{
				id         : 1,
				name       : 'a',
				fullname   : 'Corrugated Roofing Sheet Making Machine',
				selected   : false,
				desc       : "This machine can form a higher pit shape than the older generation machine. " +
					"<br><br>Improved leakage resistance, good stability, stronger, aesthetically pleasing and practical. " +
					"<br><br>Customisable, it can be cut to different lengths and up to 40 feet. This can solves the overlapping problem due to lack of length. " +
					"<br><br>Applications: site hoarding, garage, farm, warehouse, village house roof, factory building, etc.",
				detail     : ["Thickness: 0.35mm-0.7mm, Model: SF-6 / SF-800"],
				img        : '/img/浪板成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad97113d"
			},
			{
				id         : 2,
				name       : 'b',
				fullname   : 'CNC Hydraulic Shear Machine',
				selected   : false,
				desc       : "San Fung has several CNC hydraulic shearing machines, which can cut and fold steel into different steel products." +
					"<br><br>Our customer can supply the material and we can cut and fold it to the required length and design. " +
					"<br><br>The thickness ranges from 0.5-12mm. " +
					"<br><br>Suitable for cutting and bending various types of containers, pits, truck pit buckets, electric boxes, drains, various hardware products, iron plates, stainless steel plates and other metal steel plates.",
				detail     : [],
				img        : '/img/全電腦數控.png',
				productRef : "/products?id=619e810a4b9d120cad971119"
			},
			{
				id         : 3,
				name       : 'c',
				fullname   : 'CNC Steel Sheet Straightening & Shearing Machine(6.0mm)',
				selected   : false,
				desc       : "This Taiwan made machine is currently the most sophisticated and advanced steel sheet straightening and shearing machine. It can cut different types of metal coils into different lengths and sizes according to customer use and requirements, so this reduces raw material consumption and reduces customer production costs. After the process,  a layer of protective film can be applied to the steel and then ready for packaging.",
				detail     : ["Thickness: 1.5mm-6.0mm X 5 feet wide", "Coil weight: less than 20 tons", "Steel coil inner diameter: 508mm-760mm",
					"Features: two sets of flattening (rough/thin)",
					"Steel coil types: stainless steel coil, hot rolled, galvanized, electrolytic, cold rolled, powder coating, aluminum and copper steel, etc."],
				img        : '/img/CNC鋼卷整平剪裁機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971117"
			}
		],
		[
			{
				id         : 4,
				name       : 'f',
				fullname   : 'Angle Bar Roll Forming Machine',
				selected   : false,
				desc       : "This machine can produce angle bar at different length to meet customer needs, so this reduces raw material consumption and customer production costs. It can also produce the standard 6 meters long bar.",
				detail     : ["Thickness: 2.5 to 4.5mm", "Width: 20mm x 20mm to 75mm x 75mm", "Length: 6M (customisable to different length)",
					"Angle type: hot-dip galvanised, black (no treatment)"],
				img        : '/img/角鐵成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971131"
			},
			{
				id         : 5,
				name       : 'e',
				fullname   : 'Pre-Painted Coloured Corrugated Steel Sheet Machine',
				selected   : false,
				desc       : "We use the best quality material to product this latest style pre-painted coloured corrugated steel sheet. The high quality coating makes it high rust resistant and improve the service life." +
					"<br><br>The sheet can be cut to different sizes (up to 40 feet) according to customer requirements. Besides, there are a range of colours to choose from. The sheets can be installed together by self-tapping screws, which make it very simple and efficient to use.\n",
				detail     : ["Application: site hoarding, garage, farm, warehouse, village house roof, factory building (commonly used in European and American countries), etc.",
					"Colour options: White, Gray, Dark Gray, Blue, Beige , White Green, Recycling Green, Dark Green, Foggy green (we will gradually introduce more colours for customers to choose)",
					"Thickness: 0.5mm and 0.7mm Specifications: SF-1 / SF-730, SF-2 / SF-750, SF-3 / SF-760 and SF-4 / SF-768"],
				img        : '/img/彩色焗漆環保方坑機_2.jpg',
				img2       : '/img/2彩色焗漆環保方坑機.jpg',
				productRef : "/products?id=619e810a4b9d120cad97113b"
			},

			{
				id       : 6,
				name     : 'd',
				fullname : '"STAPS" Steel Sheet Straightening & Shearing Machine(2.0mm)',
				selected : false,
				desc     : "This Netherlands made steel sheet straightening & shearing machine has a finer precision than the Taiwan made one, so it is ideal for cutting thinner steel sheets from 0.2mm to 2mm and also for stainless steel with higher hardness. After the process,  a layer of protective film can be applied to the steel and then ready for packaging.",
				detail   : ["Thickness: 0.2mm-2.0mm x 4 feet wide", "Coil weight: below 10 tons", "Coil inner diameter: 508mm-610mm",
					"Features: fine & thin",
					"Steel coil types: stainless steel coil, hot rolled, galvanized, electrolytic, cold rolled, powder coating, aluminum and copper steel, etc."],
				img      : '/img/STAPS荷蘭鋼卷整平剪裁機_3.jpg'

			},
		],
		[
			{
				id         : 7,
				name       : 'g',
				fullname   : 'C Channel Shape Forming Machine',
				selected   : false,
				desc       : "This machine can produce C channel shape bar at different length to meet customer needs, so this reduces raw material consumption and customer production costs. It can also produce the standard 6 meters long bar.",
				detail     : ["Thickness: 2.0mm-3.0mm", "Specification: 40mm x 60mm", "Length: 6M (customisable to different length)",
					"Formed C-slot type: hot-dip galvanised, black (no treatment)"],
				img        : '/img/C型槽成型機.jpg',
				productRef : "/products?id=619e810a4b9d120cad971139"

			},
			{
				id       : 8,
				name     : 'h',
				fullname : 'Automatic Steel Coil Slitting Machine',
				selected : false,
				desc     : "This machine is one of the latest, fastest and most precise slitting machine." +
					"<br><br>It can divide steel coils from large coils into small coils, which makes it more convenient for production. Therefore, this helps to reduce the production costs. Moreover, it is suitable for different types of metal steel coils, which can be divided into strips of different widths according to customer use and requirements.",
				detail   : ["Thickness: 0.3 to 3.0mm", "Width: min. 200mm to max. 4 feet",
					"Coil types: stainless steel, hot rolled, galvanised, electrolytic, cold rolled, powder coating, aluminium and copper steel, etc."],
				// img      : '/img/鋼卷自動分條機.jpg'
				img        : '/img/鋼卷自動分條機_2.jpg',
				productRef : "/products?id=619e810a4b9d120cad971115"
			}
		],
	]
}
