<template>
  <v-container fluid class="pb-10">
    <v-row v-if="loading === true" class="justify-center">
      <v-col cols="1">
        <v-progress-circular
            v-ripple="{ center: true }"
            :size="70"
            :width="7"
            color="green darken-4"
            indeterminate
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" lg="4" md="4" class="py-0">
        <v-row>
          <v-col cols="12">
            <!--            <v-img contain-->
            <!--                   class="mainImage"-->
            <!--                   :src="mainImage"-->
            <!--                   max-width="500"-->
            <!--                   aspect-ratio="1">-->
            <!--              <div class="click_to_fs" @click="showFsImg = true">-->
            <!--                <v-icon color="green darken-4">mdi-magnify</v-icon>-->
            <!--                {{ $t('click_to_fs') }}-->
            <!--              </div>-->
            <!--              <div class="next"></div>-->
            <!--              <div class="prev"></div>-->
            <!--              <div class="pagination"></div>-->
            <!--            </v-img>-->

            <swiper ref="bigSwiper"
                    :options="swiperOptionsMain"
                    @slideChange="activeChange('bigSwiper')"
                    @click-slide="handleClickSlide">
              <swiper-slide v-for="(image, index) in productDetail.images"
                            :key="index">
                <v-img :src="image.large.path"
                       contain
                       class="mainImage"
                       aspect-ratio="1">
                  <div class="click_to_fs" @click="showFsImg = true">
                    <v-icon color="white darken-4">mdi-magnify</v-icon>
                    {{ $t('click_to_fs') }}
                  </div>

                </v-img>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <!-- If we need navigation buttons -->
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </v-col>
          <v-col cols="12" v-if="productDetail.images != ''">
            <swiper ref="smallSwiper"
                    :options="swiperOptions"
                    @click-slide="handleClickSlide">
              <swiper-slide v-for="(image, index) in productDetail.images"
                            :key="index">
                <v-img :src="image.large.path"
                       width="500"
                       aspect-ratio="1"/>
              </swiper-slide>
              <!-- If we need navigation buttons -->
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="8" md="8">
        <h2>{{ productDetail['name_' + $i18n.locale] }}<span class="new" v-if="moment().diff(moment(productDetail.createdAt), 'days') <= 90">{{ $t('new') }}</span>
        </h2>
        <h3 v-if="$i18n.locale !== 'eng'">{{ productDetail.name_eng }}</h3>
        <v-divider/>
        <v-col cols="12" v-for="(product, index) in attribute" :key="index">
          <v-row style="border-bottom: 1px solid #ddd;">
            <v-col cols="3" md="3" class="" style="font-size: 14px;"><h4>{{ product['catName_' + lang] }}:</h4></v-col>
            <v-col cols="9" md="9" class="">
              <v-btn class="ma-1 text-transform-none"
                     outlined
                     v-for="(item, i) in product.attributes"
                     :key="i"
                     v-bind="size"
                     x-small
                     @click="selectAttributes(product, item)"
                     :disabled="availableClick(item)"
                     :color="setBtnColor(item.selected)"
                     :plain="plainBtn(item.selected)"
                     tag="button">
                {{ item['name_' + lang] }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="productDetail.refMachine">
          <v-row style="border-bottom: 1px solid #ddd;">
            <v-col cols="12" class="refMachine">
              {{ $t('ref_machine') }}：
              <span><a :href="productDetail.refMachine.url">{{ productDetail.refMachine['name_' + $i18n.locale] }}</a></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row style="border-bottom: 1px solid #ddd;">
            <v-col cols="3" md="3" class="" style="font-size: 14px;"><h4>{{ $t('quantity') }}:</h4></v-col>
            <v-col cols="9" md="9" class="">
              <div style="max-width: 200px;">
                <v-text-field v-model="quantity" class="centered-input">
                  <v-icon slot="append-outer" @click="plusQuantity()">mdi-plus</v-icon>
                  <v-icon slot="prepend" @click="minusQuantity()">mdi-minus</v-icon>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row style="border-bottom: 1px solid #ddd;">
            <v-col cols="3" md="3" class="" style="font-size: 14px;"><h4>{{ $t('custom') }}:</h4></v-col>
            <v-col cols="9" md="9" class="">
              <v-btn @click="custom(productDetail, quantity)">{{ $t('custom_long') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn @click="addToCart();" color="green darken-4 white--text" class="mr-3" :depressed="disableAddToCart" :disabled="disableAddToCart">{{ $t('add_to_cart') }}</v-btn>
        </v-col>
      </v-col>
      <v-col cols="12" class="pt-5" color="green darken-4">
        <v-data-table :headers="productAttributesTableHeaders"
                      :items="attributesTable"
                      item-key="name"
                      class="elevation-1"
                      id="productTable"
                      hide-default-footer
                      :page.sync="page"
                      @page-count="pageCount = $event"
                      :itemsPerPage=itemsPerPage
                      disable-sort>
          <template v-for="cat in productAttributesTableHeaders" v-slot:[`item.${cat.value}`]="{ item }">
            {{ (item[cat.value]) ? item[cat.value]["name_" + lang] : '' }}
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row class="pt-12" v-if="productDetail.description">
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t("product.detail") }}</v-card-title>
          <v-divider/>
          <v-card-text>
            <div class="ql-editor" v-html="productDetail.description"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="showFsImg" v-show="mainImage && showFsImg" @click.self="showFsImg=false">
      <v-img :src="mainImage"
             contain
             max-width="1200"
             max-height="100%"
             width="100%"></v-img>
      <div class="close" @click.prevent="showFsImg=false">
        <v-icon color="white">mdi-close</v-icon>
      </div>
    </div>
    <CustomAttrOrder ref="custom"/>
  </v-container>
</template>

<script>
import {mapState}                       from 'vuex'
// Import Swiper Vue.js components
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
// Import Swiper styles
import 'swiper/css/swiper.css'
import moment                           from 'moment/moment'
import CustomAttrOrder                  from './CustomAttrOrder'

export default {
  components : {
    Swiper,
    SwiperSlide,
    CustomAttrOrder
  },
  directives : {
    swiper : directive
  },
  computed   : {
    ...mapState({ lang : state => state.lang, user : state => state.user }),
    productAttributesTableHeaders () {
      let header = []

      if (this.productDetail.attribute_categories) {
        header = this.productDetail.attribute_categories.map(item => {
          return { text : item['name_' + this.lang], value : item._id }
        });
      }
      return header
    },
    size () {
      const size = { xs : 'x-small', sm : 'small', lg : 'large', xl : 'x-large' }[this.$vuetify.breakpoint.name];
      return size ? { [size] : true } : {}
    },
  },
  methods    : {
    async custom (productDetail, quantity) {
      if (!this.user) {
        return window.LoginAndReg.open()
      }
      this.$refs.custom.open(productDetail, quantity)
    },
    async getProductDetail () {
      this.loading = true
      this.mainImage = this.noImage
      let result = await this.$Controller.getProduct(this.productID)
      let attirbutesResult = await this.$Controller.getAttributesByProductID(this.productID)

      if (result && attirbutesResult) {
        this.productDetail = result
        if (this.productDetail.images && this.productDetail.images.length > 0 && this.productDetail.images[0] && this.productDetail.images[0].large && this.productDetail.images[0].large.path) this.mainImage = this.productDetail.images[0].large.path;

        this.productDetailAttirbutes = attirbutesResult

        this.productAttributes()
        this.productAttributesTable()
      }
      this.loading = false
    },
    handleClickSlide (index) {
      if (this.productDetail) {
        this.mainImage = this.productDetail.images[index].large.path
        this.$refs.bigSwiper.$swiper.slideTo(index)
        this.$refs.smallSwiper.$swiper.slideTo(index)
      }
    },
    handleProductMainImage () {
      if (this.productDetail) {
        this.mainImage = (this.productDetail.images && this.productDetail.images.length > 0) ? this.productDetail.images[0].large.path : this.noImage
      }
    },
    productAttributes () {
      let availableattributes = []
      let tempArray = []

      if (this.productDetail.attribute_categories) {
        this.productDetail.attribute_categories.map(item => {
          if (item.available_attributes) {
            tempArray = []
            item.available_attributes.map(att => {
              tempArray.push({
                name_tc      : att.name_tc,
                name_sc      : att.name_sc,
                name_eng     : att.name_eng,
                attributesID : att._id,
                catID        : att.category,
                published    : att.published,
                value        : att.val,
                selected     : false
              })
            })
            availableattributes.push({
              catID       : item._id,
              catName_tc  : item.name_tc,
              catName_sc  : item.name_sc,
              catName_eng : item.name_eng,
              selectedCat : false,
              attributes  : tempArray,
            })
          }
        })
      }
      this.attribute = availableattributes
    },
    productAttributesTable () {
      let table = []

      if (this.productDetailAttirbutes) {
        table = this.productDetailAttirbutes
        table.forEach((productAttribute, key) => {
          productAttribute.attributes.forEach(attrubute => {
            table[key][attrubute.category] = {
              name_tc : attrubute.name_tc, name_sc : attrubute.name_sc, name_eng : attrubute.name_eng
            }
          })
        })
      }
      this.attributesTable = table
      this.orgAttributesTable = table
    },
    selectAttributes (product, item) {
      //Handle Buttons
      if (product.selectedCat && item.selected === true) { //unselect
        product.selectedCat = false
      } else {
        //non select
        product.selectedCat = true
      }

      item.selected = !item.selected

      //Handle Table
      let table = this.orgAttributesTable
      if (item.selected) {
        this.selectAttObj[item.catID] = item['name_' + this.lang]
      } else {
        delete this.selectAttObj[item.catID]
      }

      if (Object.keys(this.selectAttObj).length > 0) {
        for (const att in this.selectAttObj) {
          table = table.filter(attribute => {
            return attribute[att]['name_' + this.lang] == this.selectAttObj[att];
          });
        }
      }

      this.attributesTable = table
      if (this.attributesTable.length == 1) this.disableAddToCart = false;
    },
    setBtnColor (state) {
      return (state === true) ? 'green darken-4' : ''
    },
    plainBtn (state) {
      return (state === true) ? false : true
    },
    availableClick (item) {
      let canClick = true
      this.attributesTable.map(attribute => {
        for (let cat of this.productAttributesTableHeaders) {
          if (attribute[cat.value]) {
            if (Object.values(attribute[cat.value]).indexOf(item['name_' + this.lang]) > -1) canClick = false
          }
        }
      })

      return canClick
    },
    resetPage () {
      /*
       this.productDetail = []
       this.productDetailAttirbutes = []
       this.mainImage = ''
       this.attribute = []
       this.attributesTable = []
       this.orgAttributesTable = []
       */
      Object.assign(this.$data, this.$options.data())
    },
    activeChange (swiper) {
      const index = this.$refs[swiper].$swiper.activeIndex

      this.$refs.bigSwiper.$swiper.slideTo(index)
      this.$refs.smallSwiper.$swiper.slideTo(index)
    },
    plusQuantity () {
      this.quantity++;
    },
    minusQuantity () {
      if (this.quantity > 1) this.quantity--;
    },
    async addToCart () {
      try {
        if (!this.user) {
          return window.LoginAndReg.open()
        }
        if (this.attributesTable.length == 1) {
          let product_id = this.attributesTable[0]['product_id'];
          let attributes = this.attributesTable[0]['attributes'].map(item => {
            return item._id
          });
          let quantity = this.quantity;
          await this.$Controller.addToCart(product_id, attributes, quantity);
          window.sanfungCart.update()
          // window.location.reload();
        }
      } catch (error) {

      }
    }
  },
  props      : {
    productID : {
      type     : String,
      required : true
    }
  },
  watch      : {
    productID : {
      handler   : function () {
        this.resetPage()
        this.getProductDetail();
      },
      deep      : true,
      immediate : true
    }
  },
  data       : () => ({
    moment,
    showFsImg               : false,
    attribute               : [],
    swiperOptionsMain       : {
      slidesPerView  : 1,
      slidesPerGroup : 1,
      spaceBetween   : 20,
      pagination     : {
        el   : '.swiper-pagination',
        type : 'fraction'
      },
      navigation     : {
        nextEl : '.swiper-button-next',
        prevEl : '.swiper-button-prev'
      },
    },
    swiperOptions           : {
      slidesPerView  : 3,
      slidesPerGroup : 3,
      spaceBetween   : 20,
      navigation     : {
        nextEl : '.swiper-button-next',
        prevEl : '.swiper-button-prev'
      }
    },
    attributesTable         : [],
    orgAttributesTable      : [],
    mainImage               : '',
    noImage                 : 'https://tomokiyo.info/wp-content/themes/dp-voyageur/img/post_thumbnail/noimage.png',
    selectAttObj            : {},
    productDetail           : [],
    productDetailAttirbutes : [],
    loading                 : false,
    pageCount               : 0,
    page                    : 1,
    itemsPerPage            : 10,
    quantity                : 1,
    disableAddToCart        : true
  })
}
</script>
<style lang="less" scoped>
.showFsImg {
  width: 100%;
  height: 100%;
  padding: 50px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: rgba(0, 0, 0, 0.8);

    .v-icon {
      font-size: 50px;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0px;
  }

}

.mainImage {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
}

.click_to_fs {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  color: white;
  margin: 5px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.3);
}

.refMachine {
  padding: 20px 12px;
  font-size: 20px;
  font-weight: bold;

  a {
    color: #1B5E20;
  }
}
</style>
