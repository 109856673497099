<template>
  <v-navigation-drawer
      v-model="drawer"
      style="z-index:100;"
      right
      temporary
      width="600"
      height="100vh"
      app>
    <div style="display:flex;padding:10px">
      <v-list-item-title>{{ user.name }}</v-list-item-title>
      <v-spacer></v-spacer>
      <v-icon @click="drawer=false;">mdi-close</v-icon>
    </div>

    <v-divider></v-divider>
    <v-tabs v-model="model" color="accent-4">
      <v-tab href="#cart">{{ $t('cart') }}</v-tab>
      <v-tab href="#order">{{ $t('order_record') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="model" style="padding-bottom: 80px;">
      <v-tab-item class="overflow-auto" value="cart">
        <v-container v-if="!pendingOrder.order_items || pendingOrder.order_items.length == 0" style="height:600px; display:flex; align-items:center; justify-content:center; margin:auto">
          <div style="height:20px;">{{ $t('no_data') }}</div>
        </v-container>
        <v-container fluid v-else>
          <v-row>
            <v-col v-for="(item,key) in pendingOrder.order_items" :key="key" cols="12" md="12">
              <v-card outlined class="mx-auto">
                <v-list-item three-line>
                  <v-list-item-avatar tile size="80" color="grey">
                    <template v-if="item.product">
                      <v-img v-if="item.product.images && item.product.images.length > 0" :src="item.product.images[0]['small']['path']"></v-img>
                    </template>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="text-overline mb-4" style="display:flex">
                      <span v-html="((item.product)? item.product['name_'+lang] : $t('custom_item'))"></span>
                      <v-icon style="margin-left:auto;" @click="removeItem(item)">mdi-close</v-icon>
                    </div>
                    <div style="font-size:12px;" v-html="(item.attributes.length > 0)? item.attributes.map((attr) => {return attr['name_'+lang]}).join('; ') : item.custom_remark"></div>
                    <div style="max-width: 200px;">
                      <v-text-field v-model="item.quantity" class="centered-input">
                        <v-icon slot="append-outer" @click="plusQuantity(item)">mdi-plus</v-icon>
                        <v-icon slot="prepend" @click="minusQuantity(item)">mdi-minus</v-icon>
                      </v-text-field>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <div style="position:fixed; bottom:0; padding:10px 5px 30px 5px; display:flex; justify-content:space-around; align-items:center; width:100%; background-color: #fff;z-index:1">
          <v-btn @click="$refs.custom.open(null, 1)">{{ $t('new_custom_order') }}</v-btn>
          <v-btn v-if="pendingOrder.order_items && pendingOrder.order_items.length > 0" @click="submit()">{{ $t('submit_order') }}</v-btn>
        </div>

      </v-tab-item>
      <v-tab-item class="overflow-auto" value="order">
        <v-container dark fluid v-if="orders.length == 0" style="height:300px; display:flex; align-items:center; justify-content:center; margin:auto">
          <div style="height:20px;">{{ $t('no_data') }}</div>
        </v-container>
        <v-container fluid v-else>
          <v-row>
            <v-col v-for="(order, key) in orders" :key="key" cols="12" md="12">
              <v-card outlined class="mx-auto">
                <span style="padding:15px; margin-top: 5px; display: block;">{{ $t('order_record') }}-{{ order.order_id }}</span>
                <v-list-item three-line
                             v-for="(item,key) in order.order_items"
                             :key="key"
                             cols="12">
                  <v-list-item-avatar tile size="80" color="grey">
                    <template v-if="item.product">
                      <v-img v-if="item.product.images && item.product.images.length > 0" :src="item.product.images[0]['small']['path']"></v-img>
                    </template>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="text-overline mb-4 flex-wrap" style="display:flex; width:100%">
                      <div class="d-flex justify-content-between col-12">
                        <span style="line-height: 20px" v-html="((item.product)? item.product['name_'+lang] : $t('custom_item'))"></span>
                        <div style="margin-left:auto;line-height: 20px" class="d-flex flex-column">
                          <span style="text-transform: none;">{{ $t('quantity') }}:{{ item.quantity }}
                            <template v-if="order.quotation.itemPrice && order.quotation.itemPrice[key] && order.quotation.itemPrice[key].unitPrice">
                             x${{ order.quotation.itemPrice[key].unitPrice }}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="col-12" style="font-size:12px; line-height: 14px; text-decoration: none; color: #555; text-transform: none;" v-html="(item.attributes.length > 0)? item.attributes.map((attr) => {return attr['name_'+lang]}).join('; ') : item.custom_remark"></div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <div style="display:flex; padding: 15px;">
                  <div style="font-size: 12px;">{{ $t('submit_time') }}:{{
                      moment(order.created_at).format("DD-MM-YYYY")
                    }}
                  </div>
                  <div v-if="order.quotation.price" style="margin-left:auto; font-size:15px;">{{ $t('total_price') }}: ${{ order.quotation.price }}</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <CustomAttrOrder ref="custom" @reloadOrderItems="getOrderItems"/>
  </v-navigation-drawer>
</template>

<script>
import {mapState}      from 'vuex'
import moment          from 'moment/moment'
import CustomAttrOrder from '../view/product/CustomAttrOrder';

export default {
  data () {
    return {
      moment,
      model        : 'cart',
      drawer       : false,
      pendingOrder : {},
      orders       : []
    }
  },
  computed : {
    ...mapState({ lang : state => state.lang, user : state => state.user }),
  },
  methods  : {
    open (tab) {
      this.drawer = true
      if (tab) this.model = tab
    },
    checkPending () {

    },
    async getOrderItems () {
      try {
        let result = await this.$Controller.getOrderItems();
        if (result) {
          this.pendingOrder = result;
          if (this.pendingOrder.order_items && this.pendingOrder.order_items.length > 0) {
            this.$emit('updateOrderItemsNumber', this.pendingOrder.order_items.length);
          } else {
            this.$emit('updateOrderItemsNumber', 0);
          }
        }
      } catch (error) {
      }
    },
    async plusQuantity (orderItem) {
      try {
        orderItem.quantity += 1;
        await this.$Controller.updateOrderItems(orderItem);
      } catch (error) {

      }
    },
    async minusQuantity (orderItem) {
      try {
        if (orderItem.quantity > 1) {
          orderItem.quantity -= 1;
          await this.$Controller.updateOrderItems(orderItem);
        }
      } catch (error) {

      }
    },
    async removeItem (orderItem) {
      try {
        this.pendingOrder.order_items.splice(this.pendingOrder.order_items.indexOf(orderItem), 1);
        await this.$Controller.updateOrder(this.pendingOrder);
        this.getOrderItems()
      } catch (error) {

      }
    },
    async submit () {
      await this.$Controller.submitOrder();
      await this.getOrderItems();
      await this.getOrders();
      this.model = "order"
    },
    async getOrders () {
      try {

        let result = await this.$Controller.getOrders();
        if (result) {
          this.orders = result;
          this.orders.map(((order, key) => {
            order.order_id = (key + 1)
          }));
          this.orders.sort((a, b) => {
            if (a.created_at > b.created_at) return -1;
            if (a.created_at < b.created_at) return 1;
            return 0;
          });
        }
      } catch (error) {
      }
    },
    async update () {
      this.getOrderItems();
      this.getOrders();
    }
  },

  mounted () {
    this.getOrderItems();
    this.getOrders();
    // set this component globally for Header.vue to access
    window.sanfungCart = this
  },
  components : {
    CustomAttrOrder
  }
}
</script>
