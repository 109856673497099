<template>
  <v-container fluid class="pb-10">
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" color="green darken-4">
          <v-tab disabled style="font-size: 20px" class="black--text font-weight-bold pl-0">{{ productCat }}</v-tab>
          <!--<v-tab>Item Two</v-tab>
          <v-tab>Item Three</v-tab>-->
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(product, index) in paginatedData" :key="index" class="d-flex child-flex" cols="12" lg="4" md="6">
        <v-img
            class="pointer"
            :src="product.image"
            aspect-ratio="1"
            @click="onClickProduct(product)">
          <p class="pointer category_product_text align-center justify-center white--text font-weight-bold">
            {{ product['name_' + lang] }}<span class="new" v-if="moment().diff(moment(product.createdAt), 'days') <= 90">{{ $t('new') }}</span>
          </p>
        </v-img>
      </v-col>
      <v-col cols="12">
        <v-pagination
            v-if="paginatedData.length > 0"
            v-model="pageNumber"
            :length="pageCount"
            @input="nextPage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import moment     from 'moment/moment'

export default {
  props    : {
    productArr : {
      type     : Array,
      required : true
    },
    productCat : {
      type     : String,
      required : true
    }
  },
  computed : {
    ...mapState({ lang : state => state.lang }),
    paginatedData () {
      const start = this.pageNumber * this.prePage - this.prePage,
          end = start + this.prePage;
      return this.productArr.slice(start, end);
    },
    pageCount () {
      let totalPage = 1

      if (this.productArr) {
        let arrayLength = this.productArr.length
        let prepage = this.prePage;
        totalPage = Math.ceil(arrayLength / prepage);
      }

      return totalPage
    },
  },
  methods  : {
    onClickProduct (product) {
      this.$emit('clicked', product.id)

      this.$router.push({ name : 'Products', query : { id : product.id } }).catch(() => {
      });
    },
    nextPage (page) {
      this.pageNumber = page;
    }
  },
  data     : () => ({
    productList     : [],
    productTypeList : [],
    open            : [],
    active          : [],
    products        : [],
    tab             : [],
    pageNumber      : 1,
    prePage         : 9,
    moment
  })
}
</script>
