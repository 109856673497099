<template>
  <v-container class="pa-0" fluid style="margin-top: -60px;">
    <!--    <v-row>-->
    <!--      <v-col>-->
    <!--        <v-img-->
    <!--            min-width="200"-->
    <!--            src="@/assets/images/about_top.jpg"-->
    <!--            contain-->
    <!--        />-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-card tile flat color="#f0f0f0">
      <v-container>
        <v-row class="py-5">
          <v-col cols="12">
            <p class="descriptiontitle font-weight-bold text-center green--text text--darken-4 mt-5 mb-0">
              {{ $t("aboutus.title") }}
            </p>
            <p class="description pl-5  text--primary font">
              {{ $t("aboutus.desctiption1.line1") }} {{ $t("aboutus.desctiption1.line2") }}<br>{{ $t("aboutus.desctiption1.line3") }}
            </p>
          </v-col>

          <v-col cols="12">
            <p class="descriptiontitle font-weight-bold text-center green--text text--darken-4 mt-5 mb-0">
              {{ $t("aboutus.title2") }}
            </p>
          </v-col>

          <v-col cols="12">
            <p class="description pl-5  text--primary font">
              {{ $t("aboutus.desctiption2.line1") }}
            </p>
          </v-col>

          <v-col cols="12">
            <v-img src="@/assets/images/about_mental.jpg"/>
          </v-col>

          <v-col cols="12">
            <v-img src="@/assets/images/about_factory.jpg"/>
          </v-col>


          <v-col cols="12">
            <p class="descriptiontitle font-weight-bold text-center green--text text--darken-4 text--darken-3 mt-5 mb-0">
              {{ $t("aboutus.title3") }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="description pl-5  text--primary font">
              {{ $t("aboutus.desctiption3.line1") }}
            </p>
          </v-col>
        </v-row>

        <v-row class="py-5">
          <v-col cols="12" md="12" class="px-0">
            <v-img
                src="@/assets/images/about_logistic.jpg"
            />
          </v-col>
        </v-row>


        <v-col cols="12">
          <p class="descriptiontitle font-weight-bold text-center green--text text--darken-4 mt-12 mb-0">
            {{ $t("aboutus.title4") }}
          </p>
        </v-col>

        <v-row class="py-1">
          <v-card tile flat color="#f0f0f0" style="margin: 0 auto;">
            <v-container>
              <v-row class="py-5 mt-12" v-for="(address,i) in $t('contactus')" :key="i" style="border-bottom: 1px solid #ccc;">
                <v-col cols="12" md="6">
                  <v-img width="500" height="400"
                         class="ma-auto addrImg"
                         position="left center"
                         :src="address.img"/>
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                  <div>
                    <table class="addrTable">
                      <tr v-for="(val,key) in address.table" v-bind:key="key">
                        <td width="80" valign="top" align="left">{{ key }}:</td>
                        <td v-html="val" valign="top" align="left"></td>
                      </tr>
                      <tr>
                        <td>
                          <v-img src="@/assets/images/wa.png" height="28" width=28></v-img>
                        <td>
                          <a class="black--text" style="text-decoration: none;" href="https://wa.me/+85254013775" target="_blank">(852) 5401 3775</a>,
                          <a class="black--text" style="text-decoration: none;" href="https://wa.me/+85263604510" target="_blank">(852) 6360 4510</a>
                        </td>
                      </tr>
                    </table>
                    <br>
                  </div>
                  <iframe style="width: 450px;max-width: 100%; height: 300px;" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" :src="address.gmap"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data : () => ({
    imagearr : ['prue_black.png', 'home_img2.jpg', 'black.png']
  })
}
</script>
