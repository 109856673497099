<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            src="@/assets/images/video_top.jpg"
            cover
            max-height="500"
        />
      </v-col>
    </v-row>
    <v-card tile flat color="#d6d6d6">
      <v-container>
        <v-row v-for="(video,key) in $t('home.video')" :key="key">
          <v-col cols="12" class="py-0">
            <p class="descriptiontitle font-weight-bold  text-center green--text text--darken-4 mt-5 mb-0">
              {{ video.title }}
            </p>
            <p class="description font-weight-bold text-center text--primary font">
              {{ video.info }}
            </p>
          </v-col>
          <v-col cols="12">
            <iframe min-width="200" width="100%" class="ytvideo" :src="video.youtube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data : () => ({
    pageintroduction  : '專門經營進出口鋼鐵材料及生產各類五金鋼鐵製品',
    pageintroduction2 : '生產不同類型的鋼鐵製品',
  })
}
</script>

