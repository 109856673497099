<template>
  <v-container fluid class="pa-0 pb-10">
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            src="@/assets/images/product_top.jpg"
            cover
            max-height="500"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p class="descriptiontitle font-weight-bold text-center green--text text--darken-4 mt-5 mb-0">
          {{ $t("product_category.title") }}
        </p>
      </v-col>
    </v-row>

    <v-row class="category ma-0 d-flex">
      <v-col
          v-for="(product, key) in productList"
          :key="key"
          class="d-flex child-flex pa-0" cols="12" lg="4" md="6"
      >
        <router-link class="text-decoration-none" :to="{ name: 'Products', params: {catid: product._id } }">
          <v-img :src="(product.image)? product.image.large.path : 'https://via.placeholder.com/500x500'"
                 height="500"
                 aspect-ratio="1"
                 class="pointer grey lighten-2">

            <p class="categorytext align-center justify-center white--text font-weight-bold">{{ product["name_" + lang] }}</p>
            <div class="black-overlay"></div>
          </v-img>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'

export default {
  mounted () {
    this.getProductCatList()
  },
  computed : {
    ...mapState({
      lang : state => state.lang
    })
  },
  methods  : {
    async getProductCatList () {
      let result = await this.$Controller.getActiveCategories()
      if (result) {
        this.productList = result
      }
    }
  },
  data     : () => ({
    productList : [],
  })
}
</script>
<style lang="less" scoped>
.black-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.categorytext {
  font-size: 37px;
}

.morecategory {
  font-size: 45px;
}
</style>
