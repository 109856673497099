<template>
  <v-app>
    <v-main>
      <Header/>
      <div style="padding-top:60px;">
        <router-view/>
      </div>
      <Footer/>
    </v-main>
    <v-icon dark class="pr-2 scrollToTop" @click="scrollToTop">mdi-arrow-up-bold</v-icon>
    <template v-if="user">
      <v-badge v-if="order_items > 0"
               color="red"
               :content="order_items"
               overlap
               class="cart">
        <v-icon dark @click="$refs.cart.open()" class="cart">mdi-cart</v-icon>
      </v-badge>
      <v-icon v-else dark @click="$refs.cart.open()" class="cart">mdi-cart</v-icon>
      <Cart ref="cart" @updateOrderItemsNumber="updateOrderItemsNumber"/>
    </template>
  </v-app>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import Header                   from './components/common/Header';
import Footer                   from './components/common/Footer';
import Cart                     from './components/common/Cart';
import $                        from 'jquery'
import Cookies                  from 'js-cookie'

export default {
  name : 'App',
  async created () {
    //  trigger get user when token detected
    try {
      if (Cookies.get('token')) {
        const user = await this.$api.get('/users/me')
        if (user.data) this.setUser(user.data)
      }
    } catch (error) {

    }
  },
  computed : {
    ...mapState(['languages', 'user'])
  },
  data () {
    return {
      order_items : 0
    }
  },
  mounted () {
    $('.scrollToTop').hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() >= 150) {
        $('.cart').css('right', '70px');
        $('.scrollToTop').show();
      } else {
        $('.scrollToTop').hide();
        $('.cart').css('right', '10px');
      }
    })
  },
  methods    : {
    ...mapMutations(['setUser']),
    updateOrderItemsNumber (number) {
      this.order_items = number;
    },

    scrollToTop () {
      $('body, html').animate({
            scrollTop : 0
          }, 500,
          function () {
          } // callback method use this space how you like
      );
    }
  },
  components : {
    Header,
    Footer,
    Cart
  }
};
</script>

<style>
@import './assets/style/main.css?1';
</style>
