<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
          <v-img
            min-width="200"
            src="@/assets/billboard/machines.png"
          />
      </v-col>
    </v-row>
    <v-card tile flat color="#f0f0f0">
      <v-container class="px-5" fluid>
        <v-row class="py-5">
          <v-col>
            <p class="descriptiontitle font-weight-bold green--text text--darken-4 mt-5 mb-0"  >
             {{ $t("machineslist.title")}}
            </p>
          </v-col>
        </v-row>

        <v-row class="py-5 px-0">
          <v-col cols=12 md=4>
            <router-link class="text-decoration-none black--text" :to="{ name: 'MachinesDetail'}">
              <v-img src="@/assets/500x300.jpg" style="border: 1px solid #1d7143;"/>
              <p class="machines pt-2"><v-icon color="green darken-4">mdi-chevron-right-circle</v-icon> 裁剪機 </p>
            </router-link>
          </v-col>
          <v-col cols=12 md=4>
            <router-link class="text-decoration-none black--text" :to="{ name: 'MachinesDetail'}">
              <v-img src="@/assets/500x300.jpg" style="border: 1px solid #1d7143;"/>
              <p class="machines pt-2"><v-icon color="green darken-4">mdi-chevron-right-circle-outline</v-icon> 裁剪機 </p>
            </router-link>
          </v-col>
          <v-col cols=12 md=4>
            <router-link class="text-decoration-none black--text" :to="{ name: 'MachinesDetail'}">
              <v-img src="@/assets/500x300.jpg" style="border: 1px solid #1d7143;"/>
              <p class="machines pt-2"><v-icon color="green darken-4">mdi-chevron-right-circle-outline</v-icon> 裁剪機 </p>
            </router-link>
          </v-col>
        </v-row>

        <v-row>
          <v-col>

          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () =>({

  })}
</script>
