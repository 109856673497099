import axios   from 'axios'
import Cookies from 'js-cookie'
import store   from './store'

const hostname = window.location.hostname
const devApiRoot = 'http://localhost:3000/'
// const devApiRoot = 'https://api-dev.sanfungsteel.com.hk/'
// const devApiRoot = 'https://api-dev.sanfungsteel.com.hk/'
const prodApiRoot = 'https://api.sanfungsteel.com.hk/'

const api = axios.create({
	baseURL : hostname.includes('localhost') || hostname.includes('192.168') || hostname.includes('dev.sanfungsteel.com.hk') ? devApiRoot : prodApiRoot,
})

api.interceptors.request.use((request) => {
	request.headers.Authorization = `Bearer ${ Cookies.get('token') }`;
	return request;
});

axios.interceptors.response.use(response => {
	return response;
}, error => {
	if (401 === error.response.status) {
		Cookies.remove('token')
		Cookies.remove('refresh_token')
		window.location.reload()
	} else {
		return Promise.reject(error)
	}
})

// api.defaults.headers.common['Authorization'] = 'bearer
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InJvbGUiOiJ1c2VyIiwiY291bnRyeV9jb2RlIjowLCJjcmVhdGVkX2F0IjoxNjE0NTIyMTA0OTE1LCJ1cGRhdGVkX2F0IjoxNjQ3NjYzNjM5MjMzLCJzdGF0dXMiOiJhY3RpdmUiLCJfaWQiOiI2MDNiYTZmODg4NmY0NDRmZTA4Njc0ODAiLCJuYW1lIjoic3RyaW5nIiwicGhvbmUiOiI1NTc3NzQyOCIsIl9fdiI6MH0sImlhdCI6MTY0Nzc0NTQyMCwiZXhwIjoxNjQ4OTU1MDIwfQ.VqVBIafF31TocVOxu4NAVNsKgTainjDoI3MQdM-awM8';

export default api
