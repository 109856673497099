<template>
  <v-dialog v-model="dialog"
            persistent
            max-width="500px"
            hide-overlay
            transition="dialog-bottom-transition"
            no-click-animation>
    <v-toolbar>
      <v-toolbar-title>{{ $t("chgpw") }}</v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field :label="$t('oldpw')"
                          type="password"
                          v-model="password"/>
          </v-col>
          <v-col cols="12">
            <v-text-field :label="$t('newpw')"
                          type="password"
                          v-model="newPassword"/>
          </v-col>
        </v-row>
        <v-row class="red--text" col="12" v-if="errorMessage">
          {{ errorMessage }}
        </v-row>
        <v-row class="green--text text--darken-3" col="12" v-if="successMessage">
          {{ successMessage }}
        </v-row>
        <v-row class="justify-end">
          <v-btn text @click="savePW">{{ $t("close") }}</v-btn>
          <v-btn text @click="savePW('save')" :loading="loading" :disabled="loading">{{ $t("confirm") }}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name    : "ChangePassword",
  data    : () => ({
    dialog         : false,
    newPassword    : '',
    password       : '',
    errorMessage   : '',
    successMessage : '',
    loading        : false
  }),
  methods : {
    open () {
      this.dialog = true
      this.password = ''
      this.newPassword = ''
      this.successMessage = ''
      this.errorMessage = ''
    },
    async savePW (action) {
      try {
        if (action == 'save') {

          if (!this.password || !this.newPassword) return

          this.loading = true
          let result = await this.$Controller.changePassword(this.password, this.newPassword)

          console.log(result)
          if (result.ok) {
            //  TODO success message
            console.log(result)

            this.errorMessage = ''
            this.successMessage = this.$t('update_success')

            const t = this
            setTimeout(() => {
              t.dialog = false
              t.successMessage = ''
            }, 1500)
          } else {
            this.errorMessage = result
          }
        } else {
          this.dialog = false
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
