<template>
  <div class="not-found">
    <h1>oops! 404 page not found.</h1>
  </div>
</template>

<script>
// @ is an alias to /src
export default {}
</script>
<style lang="less" scoped>
@import "@/assets/less/mixins.less";

.not-found {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 100px 30px 200px 30px;
  background-color: #eee;
  @media @mobile {
    padding: 30px 10px 200px 10px;
  }

  h1 {
    margin-top: 100px;
    text-align: center;
  }
}
</style>
