<template>
  <v-container fluid class="pb-10 pt-12">
    <!--    <v-row>-->
    <!--      <v-col class="pa-0">-->
    <!--        <v-img-->
    <!--            min-width="200"-->
    <!--            src="@/assets/billboard/product_detail.png"-->
    <!--            contain-->
    <!--        />-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-row class="mt-10">
      <v-col cols="4" class="expandProductMenu" @click="expandProductMenu">
        {{ $t('expand') }}
        <v-icon color="green darken-4">mdi-arrow-down-drop-circle</v-icon>
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="3" class="productMenu">
        <v-col cols="5" class="closeProductMenu" @click="closeProductMenu" style="position: absolute; top: 0; right: 0">
          {{ $t('collapse') }}
          <v-icon color="green darken-4">mdi-close</v-icon>
        </v-col>
        <v-sheet>
          <v-text-field v-model="search" :label="$t('search')"
                        flat
                        outlined
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"/>
        </v-sheet>
        <v-treeview class="pt-5 font-weight-bold text-transform-none" style="font-size:18px" :items="productsTreeVeiw" open-on-click :search="search" color="green darken-4" activatable shaped hoverable>
          <template v-slot:label="{ item, active }">
            <div @click="getProductPageView(item)" class="pointer text-transform-none" style="width:100%;margin:-5px;padding:5px">
              {{ item['name_' + lang] }}
            </div>
          </template>
        </v-treeview>
      </v-col>

      <v-col cols="12" xl="10" lg="9" md="9" class="pa-0">
        <ProductList v-if="productView === 'cat'"
                     :productArr="productListArr"
                     :productCat.sync="productCatName"
                     @clicked="onClickProductChild"/>
        <ProductDetail v-else
                       ref="detailChild"
                       :productID.sync="productID"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState}    from 'vuex'
import ProductList   from "@/components/view/product/ProductList"
import ProductDetail from "@/components/view/product/ProductDetail"
import $             from "jquery"

export default {
  mounted () {
    this.getTreeView()
    this.getURLID()
  },
  components : {
    ProductList,
    ProductDetail
  },
  computed   : {
    ...mapState({ lang : state => state.lang })
  },
  methods    : {
    expandProductMenu () {
      $('.productMenu').addClass('expand')
    },
    closeProductMenu () {
      $('.productMenu').removeClass('expand')
    },
    async getTreeView () {
      let activeCatResult = await this.$Controller.getActiveCategories()
      let activeCatArr = []
      activeCatArr = activeCatResult.map(product => {
        return {
          id       : product._id,
          name     : product['name_' + this.lang],
          name_tc  : product.name_tc,
          name_sc  : product.name_sc,
          name_eng : product.name_eng,
          type     : 'cat',
          children : []
        }
      })
      this.productsTreeVeiw = activeCatArr
      let productCatID = (this.$route.params.catid) ? this.$route.params.catid : ''
      var i = 0
      for (let cat of activeCatArr) {
        try {
          let productResult = await this.$Controller.getProductsByCategoryID(cat.id)
          productResult.map(product => {
            this.productsTreeVeiw[i].children.push({
              id       : product._id,
              name     : product['name_' + this.lang],
              name_tc  : product.name_tc,
              name_sc  : product.name_sc,
              name_eng : product.name_eng,
              type     : 'product'
            })
            if (productCatID === '') {
              if (i == 0) {
                this.productListArr.push(
                    {
                      id       : product._id,
                      name     : product['name_' + this.lang],
                      name_tc  : product.name_tc,
                      name_sc  : product.name_sc,
                      name_eng : product.name_eng,
                      image    : (product.images.length > 0) ? product.images[0].large.path : this.noImage
                    })
                this.productCatName = product.category['name_' + this.lang]
              }
            } else {
              if (this.productsTreeVeiw[i].id == productCatID) {
                this.productListArr.push({
                  id       : product._id,
                  name     : product['name_' + this.lang],
                  name_tc  : product.name_tc,
                  name_sc  : product.name_sc,
                  name_eng : product.name_eng,
                  image    : (product.images.length > 0) ? product.images[0].large.path : this.noImage
                })
                this.productCatName = product.category['name_' + this.lang]
              }
            }
          })
          i++
        } catch (err) {
          console.log(err)
          return false
        }
      }
    },
    async getProductPageView (item) {
      if (item.type == 'cat') {
        this.$router.push({ name : 'Products', params : { catid : item.id } }).catch(() => {
        });
        this.productCatID = item.id
        // if (typeof this.$route.params.catid != 'undefined' && this.$route.params.catid) {
        //   this.productCatID = this.$route.params.catid
        // }
        let productResult = await this.$Controller.getProductsByCategoryID(this.productCatID)
        this.productListArr = null
        this.productListArr = productResult.map(product => {
          this.productCatName = product.category['name_' + this.lang]
          return {
            id       : product._id,
            name     : product['name_' + this.lang],
            name_tc  : product.name_tc,
            name_sc  : product.name_sc,
            name_eng : product.name_eng,
            image    : (product.images.length > 0) ? product.images[0].large.path : this.noImage
          }
        })

        this.productView = 'cat'
      } else {
        this.onClickProductChild(item.id)
      }
    },
    async onClickProductChild (id) {
      this.closeProductMenu()
      this.$router.push({ name : 'Products', query : { id : id } }).catch(() => {
      });
      if (typeof this.$route.query.id !== undefined && this.$route.query.id) {
        this.productID = this.$route.query.id
      } else {
        this.productID = id
      }
      this.productView = 'detail'
    },
    async getURLID () {
      if (typeof this.$route.query.id !== undefined && this.$route.query.id) { //product ID
        this.productID = this.$route.query.id
        this.onClickProductChild(this.$route.query.id)
      } else if (typeof this.$route.params.catid !== undefined && this.$route.params.catid) {  //product Cat ID
        let productResult = await this.$Controller.getProductsByCategoryID(this.$route.params.catid)
        this.productListArr = productResult.map(product => {
          this.productCatName = product.category['name_' + this.lang]
          return {
            id       : product._id,
            name     : product['name_' + this.lang],
            name_tc  : product.name_tc,
            name_sc  : product.name_sc,
            name_eng : product.name_eng,
            image    : (product.images.length > 0) ? product.images[0].large.path : this.noImage
          }
        })
      }
    }
  },
  data       : () => ({
    search                     : null,
    productView                : 'cat',
    productsTreeVeiw           : [],
    productDetailObj           : {}, //Detail Page Obj
    productDetailAttirbutesArr : [], //Detail Page Arr
    detailMainImage            : '',
    productListArr             : [], //List Page Arr
    productCatName             : '',
    productID                  : '',
    productCatID               : '',
    noImage                    : 'https://tomokiyo.info/wp-content/themes/dp-voyageur/img/post_thumbnail/noimage.png',
  })
}
</script>
