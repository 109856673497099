<template>
  <v-container fluid class="home">
    <v-row style="background-color:#f5ebd0; margin-top: -65px;">
      <v-col cols="12">
        <v-img min-width="200"
               :src="'/img/app_promo_'+$i18n.locale+'.jpg?1'"
               contain>
          <a class="appStore" href="https://apps.apple.com/hk/app/san-fung-steel/id1613109688?l=en" target="_blank"></a>
          <a class="playStore" href="https://play.google.com/store/apps/details?id=com.creatives.expert.sanfungsteel.android" target="_blank"></a>
        </v-img>
        <!--todo app store download button mapper-->
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col class="d-flex child-flex pa-0" cols="12" lg="4" md="6"
             v-for="(product, key) in productTypeList.slice(0,6)"
             :key="key">
        <router-link class="text-decoration-none" :to="{ name: 'Products', params: {catid: product._id } }">
          <v-img :src="(product.image)? product.image.large.path : 'https://via.placeholder.com/500x500'"
                 aspect-ratio="1"
                 class="pointer grey lighten-2 homeCategoryImg">
            <div class="categorytext align-center justify-center white--text font-weight-bold">{{ product["name_" + lang] }}</div>
            <!--            <div class="black-overlay"></div>-->
          </v-img>
        </router-link>
      </v-col>

      <v-col cols="12" class="more-cat-desktop d-none d-sm-none d-md-none d-lg-flex d-xl-flex mb-0 pb-0">
        <router-link class="pointer text-decoration-none" :to="{ name: 'Products' }">
          <div class="morecategory align-center justify-center white--text font-weight-bold px-16 ">{{ $t("home.more_products") }}</div>
        </router-link>
      </v-col>
      <v-col class="more-cat-mobile mb-3 pl-6 d-none d-sm-block d-md-block d-lg-none d-xl-none">
        <router-link class="pointer text-decoration-none" :to="{ name: 'ProductCategory' }">
          <v-btn color="grey" outlined tile block class="moreitems">
            {{ $t("home.more_products") }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" style="background-color: #d6d6d6; padding: 30px 0;">
        <p class="font-weight-bold text-center green--text text--darken-4 mt-5" style="font-size:38px;">
          {{ $t("home.business") }}
        </p>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-card class="pb-10" tile flat color="#d6d6d6">
          <v-container>
            <!--            <p class="font-weight-bold text-center text&#45;&#45;primary font" style="font-size:42px;">-->
            <!--              {{ $t("aboutus.title") }}-->
            <!--            </p>-->
            <div>
              <v-row class="category">
                <v-col v-for="(item, index) in businesList"
                       :key="index"
                       class="d-flex child-flex pa-0"
                       cols="12"
                       :lg="(index === 'item1' || index === 'item6')? 6 : 3"
                       :md="(index === 'item1' || index === 'item6')? 6 : 3">
                  <v-img :src="business_images[index]"
                         :width="(index === 'item1' || index === 'item6')? 600 : 300"
                         max-height="250"
                         aspect-ratio="1"
                         position="left bottom"
                         style="filter:grayscale(0.2)"
                         class="grey lighten-2">
                    <div :id="(index === 'item1' || index === 'item6')? 'promotext_long' : 'promotext_short'"
                         class="white--text text-center align-center justify-center font-weight-bold mb-0 ">
                      {{ item.title }}<br>
                      <!--                      <span style="font-size: 24px; display: contents;">{{ item.sub_title }}</span>-->
                    </div>
                  </v-img>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="videoList" style="    background-color: rgb(214, 214, 214);
    border-color: rgb(214, 214, 214);">
      <v-col cols="12">
        <p class="font-weight-bold text-center green--text text--darken-4 mt-5" style="font-size:38px;">
          {{ $t("video.title") }}
        </p>
      </v-col>
      <v-col v-for="(item, index) in videoList" :key="index" class="mt-3" cols="12" lg="4" md="4">
        <v-img class="videopromo" :src="'https://img.youtube.com/vi/'+item.id+'/0.jpg'" height="400">
          <!--          <p class="videopromotext white&#45;&#45;text">-->
          <!--            {{ item.title }}-->
          <!--          </p>-->
          <!--          <p class="videopromosubtitle white&#45;&#45;text" style="font-size:14px">
                      {{ item.sub_title }}
                    </p>-->
          <!--          <p class="videopromoinfo white&#45;&#45;text" style="font-size:22px">
                      {{ item.info }}
                    </p>-->
          <div class="videopromobtn align-center justify-center ">
            <router-link class="text-decoration-none" :to="{ name: 'Video' }">
              <v-btn
                  class="white--text text-center font-weight-bold"
                  outlined
                  elevation="0"
                  large
                  tile
                  style="font-size:26px; background-color: rgba(0,0,0,0.5)"
              >
                {{ $t("home.videoBtn") }}
              </v-btn>
            </router-link>
          </div>
        </v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <v-card class="py-10" tile flat color="#f0f0f0">
          <v-container class="px-10" fluid>
            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col>
                    <p class="green--text text--darken-4 font-weight-bold" style="font-size: 22px;">{{ $t("machines.sub_title") }}</p>
                    <p class="font-weight-bold" style="font-size: 36px;">{{ $t("machines.title") }}</p>
                  </v-col>
                </v-row>
                <v-row v-for="(content, arr_index) in devicearr[$i18n.locale]" :key="arr_index" style="align-items: flex-end !important;">
                  <v-col cols="12" lg="4" md="12" v-for="(value, key) in content" :key="key">
                    <router-link class="text-decoration-none black--text" :to="{ name: 'MachinesDetail', params:{id: value.id}}">
                      <!--                      <p v-if="arr_index == 0" class="devicelist"/>-->
                      <p @mouseover="mouseOver(value.name)" style="font-size: 22px">
                        <v-icon color="green">{{ (value.selected) ? "mdi-chevron-right-circle" : "mdi-chevron-right-circle-outline" }}</v-icon>
                        {{ value.fullname }}
                      </p>
                      <p class="devicelist"/>
                    </router-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <router-link class="text-decoration-none" :to="{ name: 'Machines' }">
                      <v-btn tile
                             x-large
                             outlined
                             elevation="0"
                             class="green--text"
                             color="green darken-4">
                        {{ $t("home.more_machines_btn") }}
                      </v-btn>
                    </router-link>
                  </v-col>
                </v-row>
              </v-col>
              <!--              <v-col cols="12" md="4" class="pt-15 pl-4">-->
              <!--                <v-img src="@/assets/images/home_productline.png"/>-->
              <!--              </v-col>-->
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <v-img src="@/assets/home_img2.jpg" min-width="200" contain/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState}          from 'vuex'
import home_business_item1 from '@/assets/images/home_business_item1.jpg'
import home_business_item2 from '@/assets/images/home_business_item2.jpg'
import home_business_item3 from '@/assets/images/home_business_item3_1.jpg'
import home_business_item4 from '@/assets/images/home_business_item4_1.jpg'
// import home_business_item4 from '@/assets/images/about_logistic.jpg'
import home_business_item5 from '@/assets/images/home_business_item5.jpg'
import home_business_item6 from '@/assets/images/home_business_item6.jpg'
import devicearr           from '@/data/devicearr.js'
import _                   from 'lodash'

export default {
  created () {
    this.devicearr = devicearr
  },
  mounted () {
    this.getProductCatList()
  },
  computed : {
    ...mapState({
      lang : state => state.lang
    }),
    businesList () {
      return this.$t("home.busines_list")
    },
    videoList () {
      const video = _.slice(this.$t("home.video"), 0, 3)
      return video
    },
  },
  methods  : {
    mouseOver (target) {
      this.hoverImageVaule = target
    },
    async getProductCatList () {
      let result = await this.$Controller.getActiveCategories()
      this.productTypeList = result //Cat list
    },
  },
  data     : () => ({
    business_images : {
      item1 : home_business_item1,
      item2 : home_business_item2,
      item3 : home_business_item3,
      item4 : home_business_item4,
      item5 : home_business_item5,
      item6 : home_business_item6,
    },
    hoverImageVaule : 'a',
    productTypeList : [],
    devicearr       : []
  }),
}
</script>

<style lang="less" scoped>
.black-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.morecategory {
  font-size: 45px;
}

.appStore {
  position: absolute;
  left: 9%;
  top: 66%;
  width: 16%;
  height: 10%;
}

.playStore {
  position: absolute;
  left: 25%;
  top: 66%;
  width: 16%;
  height: 10%;
}
</style>
