<template>
  <v-row justify="center">
    <!-- Login -->
    <v-dialog v-model="dialog"
              v-if="dialog"
              max-width="600px">
      <v-card v-show="tab === 'login'">
        <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent="login">
          <v-card-title>
            <v-img max-height="60" max-width="300" src="@/assets/company_logo_small_desktop.jpg"/>
          </v-card-title>
          <v-card-text>

            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <span class="black--text">{{ $t('phone') }}</span>
                  <v-text-field type="tel" maxlength="8" class="pt-0" v-model="phone" :rules="[v => !!v || $t('validation.invalid')]" required></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <span class="black--text">{{ $t('password') }}</span>
                  <v-text-field type="password" v-model="password" class="pt-0" :rules="[v => !!v || $t('validation.invalid')]" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pr-5 white green--text text--darken-4">
                  <v-btn color="green darken-2"
                         text
                         @click="tab = 'register'">{{ $t('click_to_register') }}>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>
          <v-card-actions>
            <div class="red--text">{{ errorMessage }}</div>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">{{ $t('close') }}</v-btn>
            <v-btn color="green darken-4" type="submit" text :loading="loginLoading" :disabled="loginLoading">{{ $t('login') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <!-- Register -->
      <v-card v-show="tab === 'register'" @submit.prevent>
        <v-form ref="regForm" v-model="regValid" lazy-validation>
          <v-card-title>
            <v-img max-height="60" max-width="300" src="@/assets/company_logo_small_desktop.jpg"/>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <span class="black--text">{{ $t('user_name') }}</span>
                  <v-text-field class="pt-0" v-model="newUser.name" :rules="[v => !!v || $t('validation.invalid')]" required></v-text-field>
                </v-col>
                <v-col cols="12 relative" class="pt-0">
                  <span class="black--text">{{ $t('phone') }}</span>
                  <v-text-field class="pt-0" maxlength="8" type="tel" v-model="newUser.phone" :rules="[v => !!v || $t('validation.invalid')]" required></v-text-field>
                  <v-btn text
                         class="send-code"
                         :disabled="!parseInt(newUser.phone) || newUser.phone.length !== 8 || codeSending"
                         :loading="codeSending"
                         @click="getCode()">{{ $t('send_verify_code') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <span class="black--text">{{ $t('6_digits_verify_code') }}</span>
                  <v-text-field class="pt-0" maxlength="6" v-model="verificationCode" :rules="[v => !!v || $t('validation.invalid')]" required></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <span class="black--text">{{ $t('password') }}</span>
                  <v-text-field class="pt-0" type="password" v-model="newUser.password" :rules="[v => !!v || $t('validation.invalid')]" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pr-5 white green--text text--darken-4 pb-0">
                  <v-btn text @click="tab = 'login'"><{{ $t('back_to_login') }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="red--text">{{ errorMessage }}</div>
            <v-spacer></v-spacer>
            <v-btn color="green darken-4"
                   text
                   :disabled="!isCodeSent || loginLoading"
                   :loading="registerLoading"
                   @click="register">註冊
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div id="sign-in-button"></div>
  </v-row>
</template>

<script>

import {mapMutations}                                      from "vuex";
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";

export default {
  name : "LoginAndReg",
  mounted () {
    window.LoginAndReg = this

    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size'     : 'invisible',
      'callback' : function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // enable button
      }
    }, auth)
  },
  methods : {
    ...mapMutations(['storeLogin']),
    async getCode () {
      try {
        let t = this
        t.codeSending = true
        const auth = getAuth();
        window.confirmationResult = await signInWithPhoneNumber(auth, '+852' + t.newUser.phone, window.recaptchaVerifier)
        t.isCodeSent = true
      } catch (error) {
        console.log(error)
      } finally {
        this.codeSending = false
      }
    },
    async register () {
      try {
        // form validation
        if (!this.$refs.regForm.validate()) return

        this.registerLoading = true

        // verify code
        const codeVerification = await this.verify()
        if (!codeVerification) return

        // start submitting
        this.newUser.verify_token = codeVerification._tokenResponse.idToken
        const register = await this.$api.post('/users', this.newUser)

        if (register.data) {
          this.errorMessage = ''
          // TODO login directly if registration is completed
          this.phone = this.newUser.phone
          this.password = this.newUser.password
          await this.login()
        }
      } catch (error) {
        // TODO registration failed message
        console.log(error)
        if (error.response.data) {
          this.errorMessage = error.response.data.error.message
        } else {
          this.errorMessage = this.$t('api_error.connection_error')
        }
      } finally {
        this.registerLoading = false
      }
    },

    async verify () {
      try {
        let t = this
        return await window.confirmationResult.confirm(t.verificationCode)
      } catch (error) {
        this.errorMessage = this.$t('api_error.invalid_verify_code')
      } finally {
      }
    },

    async open () {
      this.dialog = true
      this.resetDataStatus()
    },

    async login () {
      try {
        if (!this.$refs.loginForm.validate()) return
        this.loginLoading = true
        const login = await this.$api.post('/users/login', { phone : this.phone, password : this.password })
        if (login.data) {
          this.storeLogin(login.data)
          this.dialog = false
          this.resetDataStatus()
        }
      } catch (error) {
        console.log(error)
        this.errorMessage = this.$t('api_error.login_error')
        // TODO display api error message
        // if (error.response.data) {
        //   this.errorMessage = error.response.data.error.message
        // } else {
        //   this.errorMessage = this.$t('api_error.connection_error')
        // }
      } finally {
        this.loginLoading = false
      }
    },
    resetDataStatus () {
      this.errorMessage = ''
      this.newUser = {
        phone : ''
      }
      this.phone = ''
      this.password = ''
      this.isCodeSent = false
      this.verificationCode = null
      this.valid = true
      this.regValid = true
      this.tab = 'login'
      this.loginLoading = false
      this.registerLoading = false
      this.codeSending = false
    },
  },
  data    : () => ({
    errorMessage     : '',
    loginLoading     : false,
    registerLoading  : false,
    dialog           : false,
    tab              : 'login',
    phone            : null,
    password         : null,
    valid            : true,
    regValid         : true,
    verificationCode : null,
    isCodeSent       : false,
    newUser          : {
      phone : ''
    },
    codeSending      : false
  })
}
</script>

<style scoped lang="less">
.send-code {
  position: absolute;
  top: 30px;
  right: 0px;

  @media only screen and (max-width: 768px) {
    right: -5px;
  }

}
</style>
