<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            :src="'/img/is_'+ $i18n.locale +'.png'"
        />
      </v-col>
    </v-row>
    <v-card tile flat color="#f0f0f0">
      <v-container class="px-5" fluid>

        <v-row class="py-5 px-0">
          <v-col cols=12>
            <v-img src="@/assets/images/中國標準_03.png" class="mx-auto" width="1400"/>
          </v-col>
        </v-row>
        <v-row class="py-5 px-0">
          <v-col cols=12>
            <v-img src="@/assets/images/日本標準_03.png" class="mx-auto" width="1400"/>
          </v-col>
        </v-row>
        <v-row class="py-5 px-0">
          <v-col cols=12>
            <v-img src="@/assets/images/歐盟標準_03.png" class="mx-auto" width="1400"/>
          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data : () => ({
    devicearr : []
  })
}
</script>
