import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAoBvAW8USHX_Ucwx9LLw2tD2ymZlEmpbw",
	authDomain: "fleet-fortress-302312.firebaseapp.com",
	projectId: "fleet-fortress-302312",
	storageBucket: "fleet-fortress-302312.appspot.com",
	messagingSenderId: "542987050790",
	appId: "1:542987050790:web:70c63119a5870ecb38abe7",
	measurementId: "G-B74YYHM9PC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
