<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <v-img
            min-width="200"
            src="@/assets/billboard/other.jpg"
            max-height="600"
        />
      </v-col>
    </v-row>
    <v-card tile flat color="#f0f0f0">
      <v-container class="px-5" fluid>
        <v-row class="py-5 px-0">
          <v-col cols=12 lg=8 v-if="data" style="margin:0 auto;">
            <div v-html="data['content_' + $i18n.locale]"></div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import api from "../../api";

export default {
  created () {
    this.getData()
  },
  data    : () => ({
    data : null
  }),
  methods : {
    async getData () {
      try {
        let result = await api.get('/setting/tandc')
        if (result.data) {
          this.data = result.data
        }
      } catch (err) {
        // return err.response.data.error.message
      }

    }
  }
}
</script>
