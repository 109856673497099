import Vue     from "vue"
import Vuex    from "vuex"
import api     from './api'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
	state     : {
		lang : "tc",
		user : null
	},
	mutations : {
		// 切換語系設定
		setLang (state, value) {
			state.lang = value
		},
		storeLogin (state, payload) {
			Cookies.set('token', payload.token)
			Cookies.set('refresh_token', payload.refresh_token)
			state.user = payload.user
		},
		storeLogout (state, payload) {
			Cookies.remove('token')
			Cookies.remove('refresh_token')
			state.user = null
		},
		setUser (state, payload) {
			state.user = payload.user
		},
	},
	actions   : {},
	modules   : {}
})
