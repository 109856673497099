//import axios from 'axios'
//import store from './store'
import api from './api'

export default class Controller {
	//Category
	async getActiveCategories () {
		try {
			let result = await api.get('/categories/active_list')
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async getActiveAttributeCategory () {
		try {
			let result = await api.get('/attributeCategories/active_list')
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	//By Value
	async getPublishedAttributeByCatID (id) {
		try {
			let result = await api.get('/attributes/findPublishedByCat/' + id)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async searchProduct (name) {
		try {
			let result = await api.post('/products/search', name)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async getProduct (id) {
		try {
			let result = await api.get('/products/' + id)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async getProductsByCategoryID (id) {
		try {
			let result = await api.get('/products/findPublishedByCat/' + id)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async getAttributesByProductID (id) {
		try {
			let result = await api.get('/productAttributes/findPublishedByProduct/' + id)

			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async getOrderItems () {
		try {
			let result = await api.get('/orders/pending')
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async updateOrderItems (orderItem) {
		try {
			let result = await api.put('/orderItems', orderItem)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async updateOrder (order) {
		try {
			let result = await api.put('/orders', order)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async submitOrder () {
		try {
			let result = await api.post('/orders/submit');
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async getOrders () {
		try {
			let result = await api.get('/orders/submitted');
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async addToCart (product, attributes, quantity) {
		try {
			let result = await api.post('/orderItems', { product, attributes, quantity });
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async uploadImage (image) {
		try {
			const fd = new FormData();
			fd.append('file', image, this.image)
			let result = await api.post('/uploadImage', fd);
			if (result.data) {
				console.log(result)
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async customSubmit (product, quantity, custom_remark, custom_images) {
		try {
			let result = await api.post('/orderItems', { product, quantity, custom_remark, custom_images });
			console.log(result)
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}

	async changePassword (pw, newPW) {
		try {
			let result = await api.post('/users/changePassword', { password : pw, newPassword : newPW })
			if (result.data) {
				return result.data
			}
		} catch (err) {
			return err.response.data.error.message
		}
	}
}
